
import Vue from 'vue'
import Product from '@/components/common/cards/Product.vue'
import { mapMutations } from 'vuex'
// import { saveMyCollection } from '../../../plugins/util'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Product },
  props: {
    /**
     * Wedget A type <Props>
     * product : 상품 정보
     * color : 할인율에 색상 ( default : #003399 : 보라 - Figma 참조 )
     */
    product: {
      type: Object,
      default: () => ({
        imageUrl1: null,
        nameKr: null,
        mallName: '',
        price: null,
        discountPrice: null,
        discountRate: null,
        likeCnt: null,
        viewCnt: null,
        mallId: '',
        urlMobile: '',
        urlPc: '',
        productNo: '',
        isAvailable: true,
      }),
    },
    color: {
      type: String,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },
    onRate: {
      type: Boolean,
      default: true,
    },
    onDiscount: {
      type: Boolean,
      default: true,
    },
    gtagName: {
      type: String,
      default: '',
    },
    imageReverse: {
      type: Boolean,
      default: false,
    },
    naverDelivery: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations('BridgeStore', [
      'setShowBridge',
      'setSrc',
      'setShopId',
      'setProductNo',
      'setMallName',
    ]),
    goPage(path: string) {
      if (!this.product.isAvailable) return
      const { mallId, viewCnt } = this.product
      switch (path) {
        case 'product':
          this.$tracking(this.gtagName)
          this.$rendingProduct(this.product.productNo)
          this.product.viewCnt = viewCnt != null ? viewCnt + 1 : 0
          break
        case 'mall':
          this.$router.push({ name: 'StoreHome', query: { shopId: mallId } })
          break
      }
    },
    isLikeChange(yn: boolean, productNo: string) {
      const { likeCnt } = this.product
      this.$emit('isLikeChange', yn, productNo)
      if (yn) {
        this.product.likeCnt = likeCnt != null ? likeCnt + 1 : 0
      } else {
        this.product.likeCnt = likeCnt != null ? likeCnt - 1 : 0
      }
    },
  },
})

interface Data {}
interface Methods {
  goPage(path: string): void
  setSrc(src: string): void
  setShowBridge(payload: boolean): void
  setShopId(mallId: string): void
  setProductNo(productNo: string): void
  setMallName(mallName: string): void
  isLikeChange(yn: boolean, productNo: string): void
}
interface Computed {}
interface Props {
  product:
    | ProductItem
    | {
        imageUrl1?: string | null
        nameKr?: string | null
        mallName: string
        price?: number | null
        discountPrice?: number | null
        discountRate?: number | null
        likeCnt?: number | null
        viewCnt?: number | null
        mallId: string
        urlMobile: string
        urlPc: string
        productNo: string
        isAvailable: boolean
      }
  color: string
  aspectRatio: number
  onRate: boolean
  onDiscount: boolean
  gtagName: string
  imageReverse: boolean
  naverDelivery: boolean
}
