
import Vue from 'vue'
export default Vue.extend<Data, Methods, Props>({
  methods: {
    clickToolTipButton() {
      this.$emit('clickHandler')
    },
  },
})

interface Props {}
interface Data {}
interface Methods {
  clickToolTipButton: () => void
}
