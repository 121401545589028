import { render, staticRenderFns } from "./SingleMallPromotion.vue?vue&type=template&id=421c035b&"
import script from "./SingleMallPromotion.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SingleMallPromotion.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports