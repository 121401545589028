var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.datafetching)?_c('div',[_c('header',{staticClass:"fixed promotion-top fixed-content-area z-index-10",class:`${_setup.headerAnimClass}`},[_c('div',{staticClass:"fill-width white d-flex align-center",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex justify-space-between fill-width align-center px-10"},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":_setup.onClickPrevIcon}},[_vm._v(" $vuetify.icons.prevIcon ")]),_c('span',{staticClass:"oneLine rixgo-extrabold align-center gray900--text font-g16-24"},[_vm._v(_vm._s(_setup.getPromotionSingleData.title))]),_c('div',{staticClass:"pb-1",on:{"click":_setup.onClickShareIcon}},[_c('v-icon',[_vm._v("$vuetify.icons.shareIcon")])],1)],1)])]),_c('section',{staticClass:"category-header-margin-top pb-64"},[(_setup.getPromotionSingleData.isClosed)?_c('div',{staticClass:"fill-width pt-20 pb-20",staticStyle:{"background":"black"}},[_vm._m(0)]):_vm._e(),(
        !_setup.getPromotionSingleData.isClosed &&
        _setup.getPromotionSingleData.timerInfo.isUse
      )?_c('div',{staticClass:"fill-width py-12 oneLine"},[_c('div',{staticClass:"d-flex justify-center align-center pb-10"},[_c('div',{staticClass:"font-g16-24 campton-medium gray900--text"},[_vm._v(" "+_vm._s(_setup.getPromotionSingleData.timerInfo.title)+" ")])]),_c(_setup.FlipCountdown,{attrs:{"deadline":_setup.promotionEndDt},on:{"timeElapsed":_setup.timeElapsedHandler}})],1):_vm._e(),_vm._l((_setup.getPromotionSingleData.buttonBeforeImageUrlList),function(beforeImgUrl){return _c('div',{key:'before' + beforeImgUrl},[_c('v-img',{attrs:{"eager":"","src":beforeImgUrl,"height":"auto"},on:{"load":_setup.imgLoad}})],1)}),(_setup.getPromotionSingleData.videoUrl)?_c('div',{staticClass:"pa-7",style:(`background-color: ${_setup.getPromotionSingleData.buttonInfo.backgroundColor}`)},[_c('video',{staticClass:"fill-width",attrs:{"controls":"true","autoplay":"","muted":"","loop":"","playsinline":"","height":"auto","type":"video/mp4"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_setup.getPromotionSingleData.videoUrl,"type":"video/mp4"}})])]):_vm._e(),_c('div',{staticClass:"d-flex justify-center pt-6 pb-20 px-10",style:(`background-color: ${_setup.getPromotionSingleData.buttonInfo.backgroundColor}`)},[_c(_setup.CellookButton,{staticClass:"cellook-btn rixgo-bold font-g12-20",attrs:{"buttonTitle":_setup.getPromotionSingleData.buttonInfo.text,"background-color":_setup.getPromotionSingleData.buttonInfo.buttonColor,"textColorCode":_setup.getPromotionSingleData.buttonInfo.textColor,"borderRadius":`${
          _setup.getPromotionSingleData.buttonInfo.type === 'R' ? '' : '100px'
        }`,"buttonPadding":"10px"},on:{"click":_setup.moveToStore}})],1),_vm._l((_setup.getPromotionSingleData.buttonAfterImageUrlList),function(afterImgUrl){return _c('div',{key:'after' + afterImgUrl},[_c('v-img',{attrs:{"eager":"","src":afterImgUrl,"height":"auto"},on:{"load":_setup.imgLoad}})],1)}),(_setup.getPromotionSingleData.shoppingLiveButtonInfo.isUse)?_c('div',{staticClass:"d-flex justify-center pt-6 pb-20 px-10",style:(`background-color: ${_setup.getPromotionSingleData.shoppingLiveButtonInfo.backgroundColor}`)},[_c(_setup.CellookButton,{staticClass:"cellook-btn rixgo-bold font-g12-20",attrs:{"buttonTitle":_setup.getPromotionSingleData.shoppingLiveButtonInfo.text,"background-color":_setup.getPromotionSingleData.shoppingLiveButtonInfo.buttonColor,"textColorCode":_setup.getPromotionSingleData.shoppingLiveButtonInfo.textColor,"borderRadius":`${
          _setup.getPromotionSingleData.shoppingLiveButtonInfo.type === 'R'
            ? ''
            : '100px'
        }`,"buttonPadding":"10px"},on:{"click":_setup.goShoppingLive}})],1):_vm._e(),_c('div',[_c('v-img',{attrs:{"eager":"","aspect-ratio":375 / 300.66,"src":_setup.getPromotionSingleData.recommendGroup.mainGroup.imageUrl,"height":"auto"}})],1),(_setup.getPromotionSingleData.existsArrivalGuarantee)?_c('section',{staticClass:"mx-10 mt-10 d-flex align-center flex-row-reverse"},[_c(_setup.ToolTipButton,{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _setup.clickOutsideTooltip,
          include: _setup.include,
        }),expression:"{\n          handler: clickOutsideTooltip,\n          include: include,\n        }"}],on:{"clickHandler":_setup.onOffTooltip}}),_c(_setup.ToolTip,{staticClass:"included",attrs:{"show":_setup.showToolTip},on:{"close":function($event){_setup.showToolTip = false},"onClickHandler":_setup.setSingleExhibitionStory}})],1):_vm._e(),_c('div',{staticClass:"fill-width pb-20"},[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"}},[_c(_setup.VueSlickCarousel,_vm._b({ref:"slickcarousel",attrs:{"arrows":false},on:{"afterChange":_setup.carouselHandleActive}},'VueSlickCarousel',_setup.carouselOption,false),_vm._l((_setup.getPromotionSingleData.recommendGroup
              .mainGroup.carouselSearchProductList),function(itemList,index){return _c('div',{key:'carousel' + itemList.productNo,staticClass:"pt-10",on:{"click":_setup.goToSmartStore}},[(itemList.isArrivalGuarantee && _setup.activeCardIndex === index)?_c('v-img',{staticClass:"naver-delivery",attrs:{"src":require("../../../assets/icons/n-guaranteed2.svg"),"width":"64","height":"20"}}):_vm._e(),_c(_setup.ProductCardImage,{attrs:{"eventMallType":"single","rounded":"rounded-lg","imageIndex":index,"targetImage":_setup.activeCardIndex,"product":itemList,"aspectRatio":228 / 308,"gtagName":`collabo_${_setup.singlePromotionNo}_rolling_${_vm.$numbering(
                index + 1
              )}`},on:{"onClickBlurImage":_setup.onClickBlurImage}})],1)}),0),_c('div',{staticClass:"d-flex justify-center align-center pt-5"},[_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.slickcarousel.prev()}}},[_vm._v("$vuetify.icons.gray500PrevVector")]),_c('div',{staticClass:"font-g12-18 gray500--text campton-book text-center pt-1",staticStyle:{"width":"64px"}},[_vm._v(" "+_vm._s(_vm.$numbering(_setup.activeCardIndex + 1))+" / "+_vm._s(_vm.$numbering( _setup.getPromotionSingleData.recommendGroup.mainGroup .carouselSearchProductList.length ))+" ")]),_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.slickcarousel.next()}}},[_vm._v("$vuetify.icons.gray500NextVector")])],1)],1)],1),_vm._l((_setup.getPromotionSingleData.recommendGroup
        .mainGroup.searchProductList),function(itemList,index){return _c('div',{key:'main' + itemList.productNo,staticClass:"pl-10 pr-10",on:{"click":_setup.goToSmartStore}},[_c(_setup.ProductCardX,{staticClass:"mb-8",attrs:{"naverDelivery":itemList.isArrivalGuarantee,"product":itemList,"aspectRatio":1,"gtagName":`collabo_${_setup.singlePromotionNo}_sec01_${_vm.$numbering(
          index + 1
        )}`}})],1)}),_c('div',{staticClass:"pb-5 pt-22"},[_c('div',[_c('v-img',{attrs:{"eager":"","aspect-ratio":375 / 300.66,"src":_setup.getPromotionSingleData.recommendGroup.subGroup.imageUrl,"height":"auto"}})],1)]),_vm._l((_setup.getPromotionSingleData.recommendGroup
        .subGroup.searchProductList),function(itemList,index){return _c('div',{key:'sub' + itemList.productNo,class:`${index === 0 ? 'pt-10' : ''} pl-10 pr-10`,on:{"click":_setup.goToSmartStore}},[_c(_setup.ProductCardX,{attrs:{"naverDelivery":itemList.isArrivalGuarantee,"imageReverse":true,"product":itemList,"aspectRatio":1,"gtagName":`collabo_${_setup.singlePromotionNo}_sec02_${_vm.$numbering(
          index + 1
        )}`}}),_c('div',{class:`${
          index ===
          _setup.getPromotionSingleData.recommendGroup.subGroup.searchProductList
            .length -
            1
            ? 'mb-30'
            : 'bar mt-8 mb-8'
        }`})],1)}),_c('div',[_c('v-img',{attrs:{"eager":"","aspect-ratio":375 / 300.66,"src":_setup.getPromotionSingleData.categoryGroup.imageUrl,"height":"auto"}})],1),_vm._l((_setup.getPromotionSingleData.categoryGroup
        .categoryList),function(item,categoryListIndex){return _c('div',{key:categoryListIndex,staticClass:"font-g16-25 rixgo-bold"},[_c('div',[_c('div',{staticClass:"pa-10"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"product-y-grid-two-col fill-width px-10"},_vm._l((item.searchProductList.slice(
              0,
              _setup.paging[categoryListIndex].limit
            )),function(itemList,subListIndex){return _c('div',{key:'search' + itemList.productNo,on:{"click":_setup.goToSmartStore}},[_c(_setup.ProductCardY,{attrs:{"useSoldOut":true,"isMallNameVisible":false,"naverDelivery":itemList.isArrivalGuarantee,"product":itemList,"gtagName":`collabo_${_setup.singlePromotionNo}_cate${_vm.$numbering(
                categoryListIndex + 1
              )}_${_vm.$numbering(subListIndex + 1)}`},on:{"isLikeChange":(yn) => _setup.isLikeChange(yn, categoryListIndex, subListIndex)}})],1)}),0),_c('div',{staticClass:"pt-5 pr-10 pl-10 pb-20 mt-14"},[(
              item.searchProductList.length > 20 &&
              _setup.paging[categoryListIndex].limit === 20
            )?_c('div',{staticClass:"fill-width d-flex align-center justify-center",staticStyle:{"border":"1px solid #003399","height":"47px","border-radius":"4px"},on:{"click":function($event){return _setup.viewMore(categoryListIndex)}}},[_c('div',{staticClass:"font-g13-18 rixgo-black blue--text"},[_vm._v(" 베스트 "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"font-g13-18 rixgo-regular blue--text pl-2"},[_vm._v(" 더보기 "),_c('v-icon',{staticClass:"pl-2 pb-1",attrs:{"size":"16"}},[_vm._v("$vuetify.icons.downIcon")])],1)]):_vm._e()]),_c('div',{staticClass:"border-gray"})])])}),(_setup.availableBottomBanner)?_c('div',[_c('div',{staticClass:"font-g16-24 pa-10 rixgo-bold"},[_vm._v("다른 기획전")]),_c('div',{staticClass:"pl-10 pr-10"},_vm._l((_setup.getPromotionSingleData.bottomBannerList),function(bottomBanner,index){return _c('div',{key:index},[_c('v-img',{staticClass:"mb-8 border-radius-8",attrs:{"lazy-src":require('@/assets/images/img_back.png'),"aspect-ratio":335 / 98,"src":bottomBanner.icon},on:{"click":function($event){return _setup.goExhibitionList(bottomBanner.link, index)}}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"pt-20 pl-10 pr-10"},[_c(_setup.CellookButton,{attrs:{"buttonTitle":`MD's Pick 보기`,"background-color":`#003399`,"buttonTitleTextClass":`font-g14-20 rixgo-bold`,"text-color":`white`},on:{"click":_setup.goMainMDSPick}})],1)],2),_c(_setup.Sharing,{attrs:{"shareItem":_setup.shareItem,"show":_setup.share,"url":_setup.shareCopyUrl,"store":_setup.getPromotionSingleData.shareInfo.title,"sheetTitle":"기획전"},on:{"onClose":_setup.onCloseShare}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white--text"},[_c('div',{staticClass:"d-flex justify-center font-g14-20 rixgo-bold pb-2"},[_vm._v(" 종료된 기획전입니다. ")]),_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" 할인율과 혜택이 적용되지 않을 수 있습니다. ")])])
}]

export { render, staticRenderFns }