
import Vue from 'vue'
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    tryClose() {
      this.$emit('close')
    },
    goToIntroNaverDelivery() {
      this.$router.push('/naver-delivery')
      // 콜백 함수 - 스크롤 리포지션 등 다양하게 사용가능
      this.$emit('onClickHandler')
    },
  },
})

interface Props {
  show: boolean
}
interface Data {}
interface Computed {}
interface Methods {
  goToIntroNaverDelivery: () => void
  tryClose: () => void
}
